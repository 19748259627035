import { DEVICE } from '../parameters'
import { isBrowser } from '../services/auth'

export const getDeviceByWidth = () => {
  const width = isBrowser() ? window.innerWidth : 800

  if (768 < width) {
    return DEVICE.desktop
  }
  if (768 >= width && 560 < width) {
    return DEVICE.tablet
  }
  if (560 >= width) {
    return DEVICE.mobile
  }
}
