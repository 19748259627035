import React, { useMemo } from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import { inject, observer } from 'mobx-react'
import { ThemeProvider } from '@material-ui/styles'

const CustomThemeProvider = ({ appStore, children }) => {
  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          type: appStore.theme,
          default: { main: grey['500'] },
          primary: { light: '#81c784', main: '#4caf50', dark: '#388e3c' },
          secondary: { light: '#64b5f6', main: '#2196f3', dark: '#1976d2' },
        },
        typography: {
          fontSize: 12,
          h1: {
            fontSize: 32,
            margin: '20px 0 10px 0',
          },
          h2: {
            fontSize: 24,
            margin: '20px 0 10px 0',
          },
          h3: {
            fontSize: 20,
            margin: '10px 0 15px 0',
          },
          h4: {
            fontSize: 18,
            margin: '10px 0 15px 0',
          },
          h5: {
            fontSize: 16,
            margin: '10px 0 15px 0',
          },
          h6: {
            fontSize: 14,
            margin: '10px 0 15px 0',
          },
          body1: {
            margin: '10px 0 10px 0',
          }
        },
      }),
    [appStore.theme],
  );

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

export default inject('appStore')(observer(CustomThemeProvider))
