import { observable, action } from 'mobx'

const saveTheme = isDark => {
  window.localStorage.setItem('theme', isDark ? 'dark' : 'light');
};

const isLightTheme = () => {
  const theme = window.localStorage.getItem('theme');
  return 'light' === theme;
}

class AppStore {
  @observable
  theme = isLightTheme() ? 'light' : 'dark';

  @action
  setTheme = e => {
    this.theme = e.target.checked ? 'dark' : 'light';
    saveTheme(e.target.checked);
  }
}

const appStore = new AppStore()
export default appStore
