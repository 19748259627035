// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instruo-privacy-policy-js": () => import("./../src/pages/instruo-privacy-policy.js" /* webpackChunkName: "component---src-pages-instruo-privacy-policy-js" */),
  "component---src-pages-instruo-terms-condition-js": () => import("./../src/pages/instruo-terms-condition.js" /* webpackChunkName: "component---src-pages-instruo-terms-condition-js" */),
  "component---src-pages-instruo-js": () => import("./../src/pages/instruo.js" /* webpackChunkName: "component---src-pages-instruo-js" */)
}

