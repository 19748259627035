import { isBlank } from '../services/type-check'

export const COLOR = {
  white: '#ffffff',
  lightestGrey: '#ececec',
  lightGrey: '#d2d2d2',
  grey: '#797979',
  darkGrey: '#3a3a3a',
  mutedBlack: '#1e1e1e',
  greyTransparent: 'rgba(0,0,0,0.31)',
  black: '#000000',
  blackTransparent: '#00000099',
  lightGreen: '#c7f9a8',
  primaryGreen: '#63BC46',
  darkGreen: '#279727',
  orange: '#FF4D00',
  darkOrange: '#b54500',
  blue: '#4766ff',
  linkBlue: '#4c7dc7',
  linkBlueHover: '#4362a3',
  facebookBlue: '#4267B2',
}

export const LANGUAGE = [
  'en',
  'sv',
]

export const GENDER = {
  male: 'm',
  female: 'f',
  all: 'all'
}

export const ROLE = {
  user: 1,
  organizer: 2,
  admin: 3,
  super_admin: 4,
}

export const FILTER_OPTION = {
  all: 2,
  yes: 1,
  no: 0,
}

export const CLASS = {
  elite: 1,
  journeyMan: 2,
  runForFun: 3
}

export const HTTP_CODE = {
  ok: 200,
  created: 201,
  badRequest: 400,
  unauthorized: 401,
  notFound: 404,
}

export const API_URL = {
  // Login
  login: () => `/public/login`,
  loginFb: () => `/public/login/facebook`,
  loginGoogle: () => `/public/login/google`,
  logout: () => `/public/logout`,
  resetPwd: token => `/public/reset-password/${token}`,
  refreshToken: () => `/public/login/refresh`,
  createAccount: () => `/public/create-account`,

  // Organizations
  organizations: id => `/public/api/1.0/organizations/${!isBlank(id) ? `/${id}` : ''}`,
  orgOrganizations:  id => `/organizer/api/1.0/organizations${!isBlank(id) ? `/${id}` : ''}`,

  //Championships
  championship: id => `/public/api/1.0/championships${!isBlank(id) ? `/${id}` : ''}`,

  // Rankings
  orgRankings: id => `/organizer/api/1.0/rankings${!isBlank(id) ? `/${id}` : ''}`,
  rankingsByOrg: orgUuid => `/organizer/api/1.0/organizations/${orgUuid}/rankings`,

  // AgeGroup
  orgAgeGroups: id => `/organizer/api/1.0/age-groups${!isBlank(id) ? `/${id}` : ''}`,
  orgAgeGroupsByRankingClass: id => `/organizer/api/1.0/ranking-classes/${id}/age-groups`,

  // User
  user: id => `/user/api/1.0/users${!isBlank(id) ? `/${id}` : ''}`,
  fetchUser: () => `/user/api/1.0/users/me`,
  results: id => `/user/api/1.0/results${!isBlank(id) ? `/${id}` : ''}`,

  // Races
  races: id => `/public/api/1.0/races${!isBlank(id) ? `/${id}` : ''}`,
  orgRaces: id => `/organizer/api/1.0/races${!isBlank(id) ? `/${id}` : ''}`,
  orgRacesByOrg: id => `/organizer/api/1.0/organizations${!isBlank(id) ? `/${id}` : ''}/races`,
  orgRacesRankings: id => `/organizer/api/1.0/races/${id}/rankings`,

  // RankingRace
  orgRankingRace: id => `/organizer/api/1.0/ranking-races/${id}`,
  orgRankingRaceByRace: uuid => `/organizer/api/1.0/ranking-races/races/${uuid}`,
  orgRankingRaceByRaceAndRanking: (raceUuid, rankingUuid) => `/organizer/api/1.0/race/${raceUuid}/rankings/${rankingUuid}`,

  // RaceClasses
  orgRaceClasses: id => `/organizer/api/1.0/race-classes${!isBlank(id) ? `/${id}` : ''}`,
  orgRaceClassesByRace: raceUuid => `/organizer/api/1.0/races/${raceUuid}/race-classes`,

  // RaceStats
  orgRaceStats: id => `/organizer/api/1.0/race-stats/${id}`,
  orgRaceStatsByRaceClass: id => `/organizer/api/1.0/race-classes/${id}/race-stats`,

  // System
  systemRaceClasses: () => `/organizer/api/1.0/system-race-classes`,

  saveRow: row => `/ranking/row/${row.uuid}`,
}

export const ROUTE = {
  home: '/',
  login: '/login',
  logout: '/logout',
  races: '/app/races',
  handleRanking: '/app/handle-ranking',
  ranking: '/ranking',
  cookiePolicy: '/cookie-policy',
  termsOfUse: '/terms-of-use',
  orgRanking: '/app/org-ranking',
  members: '/app/members',
  myAccount: '/app/my-account',
  organization: '/app/organization',
  editOrganization: '/app/edit-organization',
  handleRace: '/app/handle-race',
  privacyPolicy: '/privacy-policy',
}

export const DEVICE = {
  mobile: 1,
  tablet: 2,
  desktop: 3,
}

export const CURRENCY = {
  SEK: 'SEK',
  EUR: 'EUR',
  NOK: 'NOK',
  DKK: 'DKK'
}

export const excludedCountries = []

export const COUNTRIES_WITH_ACRONYM = [
  { acro: 'GB-ENG', country: 'England' },
  { acro: 'GB-NIR', country: 'Northern Ireland' },
  { acro: 'GB-SCT', country: 'Scotland' },
  { acro: 'GB-WLS', country: 'Wales' },
  { acro: 'ES-CT', country: 'Catalonia' },
  { acro: 'AF', country: 'Afghanistan' },
  { acro: 'AX', country: 'Aland Islands' },
  { acro: 'AL', country: 'Albania' },
  { acro: 'DZ', country: 'Algeria' },
  { acro: 'AS', country: 'American Samoa' },
  { acro: 'AD', country: 'Andorra' },
  { acro: 'AO', country: 'Angola' },
  { acro: 'AI', country: 'Anguilla' },
  { acro: 'AQ', country: 'Antarctica' },
  { acro: 'AG', country: 'Antigua And Barbuda' },
  { acro: 'AR', country: 'Argentina' },
  { acro: 'AM', country: 'Armenia' },
  { acro: 'AW', country: 'Aruba' },
  { acro: 'AU', country: 'Australia' },
  { acro: 'AT', country: 'Austria' },
  { acro: 'AZ', country: 'Azerbaijan' },
  { acro: 'BS', country: 'Bahamas' },
  { acro: 'BH', country: 'Bahrain' },
  { acro: 'BD', country: 'Bangladesh' },
  { acro: 'BB', country: 'Barbados' },
  { acro: 'BY', country: 'Belarus' },
  { acro: 'BE', country: 'Belgium' },
  { acro: 'BZ', country: 'Belize' },
  { acro: 'BJ', country: 'Benin' },
  { acro: 'BM', country: 'Bermuda' },
  { acro: 'BT', country: 'Bhutan' },
  { acro: 'BO', country: 'Bolivia' },
  { acro: 'BA', country: 'Bosnia And Herzegovina' },
  { acro: 'BW', country: 'Botswana' },
  { acro: 'BV', country: 'Bouvet Island' },
  { acro: 'BR', country: 'Brazil' },
  { acro: 'BN', country: 'Brunei Darussalam' },
  { acro: 'BG', country: 'Bulgaria' },
  { acro: 'BF', country: 'Burkina Faso' },
  { acro: 'BI', country: 'Burundi' },
  { acro: 'KH', country: 'Cambodia' },
  { acro: 'CM', country: 'Cameroon' },
  { acro: 'CA', country: 'Canada' },
  { acro: 'CV', country: 'Cape Verde' },
  { acro: 'KY', country: 'Cayman Islands' },
  { acro: 'CF', country: 'Central African Republic' },
  { acro: 'TD', country: 'Chad' },
  { acro: 'CL', country: 'Chile' },
  { acro: 'CN', country: 'China' },
  { acro: 'CX', country: 'Christmas Island' },
  { acro: 'CC', country: 'Cocos (Keeling) Islands' },
  { acro: 'CO', country: 'Colombia' },
  { acro: 'KM', country: 'Comoros' },
  { acro: 'CG', country: 'Congo' },
  { acro: 'CD', country: 'Congo, Democratic Republic' },
  { acro: 'CK', country: 'Cook Islands' },
  { acro: 'CR', country: 'Costa Rica' },
  { acro: 'CI', country: "country.Cote D'Ivoire" },
  { acro: 'CU', country: 'Cuba' },
  { acro: 'CY', country: 'Cyprus' },
  { acro: 'CZ', country: 'Czech Republic' },
  { acro: 'DK', country: 'Denmark' },
  { acro: 'DJ', country: 'Djibouti' },
  { acro: 'DM', country: 'Dominica' },
  { acro: 'DO', country: 'Dominican Republic' },
  { acro: 'EC', country: 'Ecuador' },
  { acro: 'EG', country: 'Egypt' },
  { acro: 'TEXT', country: 'El Salvador' },
  { acro: 'GQ', country: 'Equatorial Guinea' },
  { acro: 'ER', country: 'Eritrea' },
  { acro: 'EE', country: 'Estonia' },
  { acro: 'ET', country: 'Ethiopia' },
  { acro: 'FK', country: 'Falkland Islands (Malvinas)' },
  { acro: 'FO', country: 'Faroe Islands' },
  { acro: 'FJ', country: 'Fiji' },
  { acro: 'FI', country: 'Finland' },
  { acro: 'FR', country: 'France' },
  { acro: 'GF', country: 'French Guiana' },
  { acro: 'PF', country: 'French Polynesia' },
  { acro: 'GA', country: 'Gabon' },
  { acro: 'GM', country: 'Gambia' },
  { acro: 'GE', country: 'Georgia' },
  { acro: 'DE', country: 'Germany' },
  { acro: 'GH', country: 'Ghana' },
  { acro: 'GI', country: 'Gibraltar' },
  { acro: 'GR', country: 'Greece' },
  { acro: 'GL', country: 'Greenland' },
  { acro: 'GD', country: 'Grenada' },
  { acro: 'GP', country: 'Guadeloupe' },
  { acro: 'GU', country: 'Guam' },
  { acro: 'GT', country: 'Guatemala' },
  { acro: 'GG', country: 'Guernsey' },
  { acro: 'GN', country: 'Guinea' },
  { acro: 'GW', country: 'Guinea-Bissau' },
  { acro: 'GY', country: 'Guyana' },
  { acro: 'GB', country: 'Great Britain' },
  { acro: 'HT', country: 'Haiti' },
  { acro: 'HM', country: 'Heard Island & Mcdonald Islands' },
  { acro: 'VA', country: 'Vatican City)' },
  { acro: 'HN', country: 'Honduras' },
  { acro: 'HK', country: 'Hong Kong' },
  { acro: 'HU', country: 'Hungary' },
  { acro: 'IS', country: 'Iceland' },
  { acro: 'IN', country: 'India' },
  { acro: 'ID', country: 'Indonesia' },
  { acro: 'IR', country: 'Iran' },
  { acro: 'IQ', country: 'Iraq' },
  { acro: 'IE', country: 'Ireland' },
  { acro: 'IM', country: 'Isle Of Man' },
  { acro: 'IL', country: 'Israel' },
  { acro: 'IT', country: 'Italy' },
  { acro: 'JM', country: 'Jamaica' },
  { acro: 'JP', country: 'Japan' },
  { acro: 'JE', country: 'Jersey' },
  { acro: 'JO', country: 'Jordan' },
  { acro: 'KZ', country: 'Kazakhstan' },
  { acro: 'KE', country: 'Kenya' },
  { acro: 'KI', country: 'Kiribati' },
  { acro: 'KR', country: 'Korea' },
  { acro: 'KW', country: 'Kuwait' },
  { acro: 'KG', country: 'Kyrgyzstan' },
  { acro: 'LV', country: 'Latvia' },
  { acro: 'LB', country: 'Lebanon' },
  { acro: 'LS', country: 'Lesotho' },
  { acro: 'LR', country: 'Liberia' },
  { acro: 'LY', country: 'Libyan Arab Jamahiriya' },
  { acro: 'LI', country: 'Liechtenstein' },
  { acro: 'LT', country: 'Lithuania' },
  { acro: 'LU', country: 'Luxembourg' },
  { acro: 'MO', country: 'Macao' },
  { acro: 'MK', country: 'Macedonia' },
  { acro: 'MG', country: 'Madagascar' },
  { acro: 'MW', country: 'Malawi' },
  { acro: 'MY', country: 'Malaysia' },
  { acro: 'MV', country: 'Maldives' },
  { acro: 'ML', country: 'Mali' },
  { acro: 'MT', country: 'Malta' },
  { acro: 'MH', country: 'Marshall Islands' },
  { acro: 'MQ', country: 'Martinique' },
  { acro: 'MR', country: 'Mauritania' },
  { acro: 'MU', country: 'Mauritius' },
  { acro: 'YT', country: 'Mayotte' },
  { acro: 'MX', country: 'Mexico' },
  { acro: 'FM', country: 'Micronesia' },
  { acro: 'MD', country: 'Moldova' },
  { acro: 'MC', country: 'Monaco' },
  { acro: 'MN', country: 'Mongolia' },
  { acro: 'ME', country: 'Montenegro' },
  { acro: 'MS', country: 'Montserrat' },
  { acro: 'MA', country: 'Morocco' },
  { acro: 'MZ', country: 'Mozambique' },
  { acro: 'MM', country: 'Myanmar' },
  { acro: 'NA', country: 'Namibia' },
  { acro: 'NR', country: 'Nauru' },
  { acro: 'NP', country: 'Nepal' },
  { acro: 'NL', country: 'Netherlands' },
  { acro: 'AN', country: 'Netherlands Antilles' },
  { acro: 'NC', country: 'New Caledonia' },
  { acro: 'NZ', country: 'New Zealand' },
  { acro: 'NI', country: 'Nicaragua' },
  { acro: 'NE', country: 'Niger' },
  { acro: 'NG', country: 'Nigeria' },
  { acro: 'NU', country: 'Niue' },
  { acro: 'NF', country: 'Norfolk Island' },
  { acro: 'MP', country: 'Northern Mariana Islands' },
  { acro: 'NO', country: 'Norway' },
  { acro: 'OM', country: 'Oman' },
  { acro: 'PK', country: 'Pakistan' },
  { acro: 'PW', country: 'Palau' },
  { acro: 'PS', country: 'Palestinian' },
  { acro: 'PA', country: 'Panama' },
  { acro: 'PG', country: 'Papua New Guinea' },
  { acro: 'PY', country: 'Paraguay' },
  { acro: 'PE', country: 'Peru' },
  { acro: 'PH', country: 'Philippines' },
  { acro: 'PN', country: 'Pitcairn' },
  { acro: 'PL', country: 'Poland' },
  { acro: 'PT', country: 'Portugal' },
  { acro: 'PR', country: 'Puerto Rico' },
  { acro: 'QA', country: 'Qatar' },
  { acro: 'RE', country: 'Reunion' },
  { acro: 'RO', country: 'Romania' },
  { acro: 'RU', country: 'Russia' },
  { acro: 'RW', country: 'Rwanda' },
  { acro: 'BL', country: 'Saint Barthelemy' },
  { acro: 'SH', country: 'Saint Helena' },
  { acro: 'KN', country: 'Saint Kitts And Nevis' },
  { acro: 'LC', country: 'Saint Lucia' },
  { acro: 'MF', country: 'Saint Martin' },
  { acro: 'PM', country: 'Saint Pierre And Miquelon' },
  { acro: 'VC', country: 'Saint Vincent And Grenadines' },
  { acro: 'WS', country: 'Samoa' },
  { acro: 'SM', country: 'San Marino' },
  { acro: 'ST', country: 'Sao Tome And Principe' },
  { acro: 'SA', country: 'Saudi Arabia' },
  { acro: 'SN', country: 'Senegal' },
  { acro: 'RS', country: 'Serbia' },
  { acro: 'SC', country: 'Seychelles' },
  { acro: 'SL', country: 'Sierra Leone' },
  { acro: 'SG', country: 'Singapore' },
  { acro: 'SK', country: 'Slovakia' },
  { acro: 'SI', country: 'Slovenia' },
  { acro: 'SB', country: 'Solomon Islands' },
  { acro: 'SO', country: 'Somalia' },
  { acro: 'ZA', country: 'South Africa' },
  { acro: 'GS', country: 'South Georgia And Sandwich Isl.' },
  { acro: 'ES', country: 'Spain' },
  { acro: 'LK', country: 'Sri Lanka' },
  { acro: 'SD', country: 'Sudan' },
  { acro: 'SR', country: 'Suriname' },
  { acro: 'SJ', country: 'Svalbard And Jan Mayen' },
  { acro: 'SZ', country: 'Swaziland' },
  { acro: 'SE', country: 'Sverige' },
  { acro: 'CH', country: 'Switzerland' },
  { acro: 'SY', country: 'Syrian Arab Republic' },
  { acro: 'TW', country: 'Taiwan' },
  { acro: 'TJ', country: 'Tajikistan' },
  { acro: 'TZ', country: 'Tanzania' },
  { acro: 'TH', country: 'Thailand' },
  { acro: 'TL', country: 'Timor-Leste' },
  { acro: 'TG', country: 'Togo' },
  { acro: 'TK', country: 'Tokelau' },
  { acro: 'TO', country: 'Tonga' },
  { acro: 'TT', country: 'Trinidad And Tobago' },
  { acro: 'TN', country: 'Tunisia' },
  { acro: 'TR', country: 'Turkey' },
  { acro: 'TM', country: 'Turkmenistan' },
  { acro: 'TC', country: 'Turks And Caicos Islands' },
  { acro: 'TV', country: 'Tuvalu' },
  { acro: 'UG', country: 'Uganda' },
  { acro: 'UA', country: 'Ukraine' },
  { acro: 'AE', country: 'United Arab Emirates' },
  { acro: 'UK', country: 'United Kingdom' },
  { acro: 'US', country: 'United States' },
  { acro: 'UM', country: 'United States Outlying Islands' },
  { acro: 'UY', country: 'Uruguay' },
  { acro: 'UZ', country: 'Uzbekistan' },
  { acro: 'VU', country: 'Vanuatu' },
  { acro: 'VE', country: 'Venezuela' },
  { acro: 'VN', country: 'Vietnam' },
  { acro: 'VG', country: 'Virgin Islands, British' },
  { acro: 'VI', country: 'Virgin Islands, U.S.' },
  { acro: 'WF', country: 'Wallis And Futuna' },
  { acro: 'EH', country: 'Western Sahara' },
  { acro: 'YE', country: 'Yemen' },
  { acro: 'ZM', country: 'Zambia' },
  { acro: 'ZW', country: 'Zimbabwe' },
]
