// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { isBrowser } from './src/services/auth'

// eslint-disable-next-line react/display-name,react/prop-types

const GlobalEventListener = ({ deviceStore }) => {
  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('resize', deviceStore.updateDevice)
    }
    return () => {
      if (isBrowser()) {
        window.removeEventListener('resize', deviceStore.updateDevice)
      }
    }
  }, [deviceStore])

  return null
}

export default inject('deviceStore')(observer(GlobalEventListener))
