import { observable, action, computed } from 'mobx'
import { DEVICE } from '../parameters'
import { getDeviceByWidth } from '../utils/device'

class DeviceStore {
  @observable
  currentDevice = getDeviceByWidth()

  @computed
  get isMobile() {
    return DEVICE.mobile === this.currentDevice
  }

  @computed
  get isTablet() {
    return DEVICE.tablet === this.currentDevice
  }

  @computed
  get isDesktop() {
    return DEVICE.desktop === this.currentDevice
  }

  @action
  updateDevice = () => {
    this.currentDevice = getDeviceByWidth()
  }
}

const deviceStore = new DeviceStore()
export default deviceStore
