import React from 'react'
import { Provider } from 'mobx-react'
import models from './src/stores'
import GlobalEventListener from './global-event-listener'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import CustomThemeProvider from './src/components/custom-theme-provider'
import CssBaseline from '@material-ui/core/CssBaseline'

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => (
  <Provider {...models}>
    <GlobalEventListener />
    <CustomThemeProvider>
      <CssBaseline />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {element}
      </MuiPickersUtilsProvider>
    </CustomThemeProvider>
  </Provider>
)
