// import { isNumber, isObject, isString } from './type-check'
// import { hasTimeStampExpired, unixTimeStampToTimeStamp } from './date'
//
// import axiosInstance from './fetch-wrapper'

// const KEY_USER = 'user'
export const isBrowser = () => 'undefined' !== typeof window

// export const getJWTToken = () => {
//   const user = getUser()
//
//   return user && isObject(user) && user.hasOwnProperty('jwtToken')
//     ? user.jwtToken
//     : null
// }
//
// const setUser = user => {
//   if (isBrowser()) {
//     window.localStorage.setItem(KEY_USER, JSON.stringify(user))
//   }
// }
//
// export const getUser = () =>
//   isBrowser() && window.localStorage.getItem(KEY_USER)
//     ? JSON.parse(window.localStorage.getItem(KEY_USER))
//     : {}
//
// export const handleLogin = jwtToken => {
//   const token = parseJwtToken(jwtToken)
//
//   if (!isObject(token)
//     || !token.hasOwnProperty('uuid')
//     || !token.hasOwnProperty('exp')
//     || !token.hasOwnProperty('role')
//     || !isNumber(token.exp)
//     || !isString(token.uuid)
//     || !isNumber(token.role)) {
//     return false
//   }
//
//   setUser({
//     uuid: token.uuid,
//     exp: unixTimeStampToTimeStamp(token.exp),
//     role: token.role,
//     jwtToken: jwtToken,
//   })
//
//   return true
// }
//
// export const isOrganizer = () => {
//   const user = getUser()
//
//   if (!isObject(user) || !user.hasOwnProperty('role')) {
//     return false
//   }
//
//   return user.role === ROLE.organizer || user.role === ROLE.admin || user.role === ROLE.super_admin
// }
//
// export const isUserSet = () => {
//   const user = getUser()
//   return isObject(user) && user.hasOwnProperty('exp')
// }
//
// export const isLoggedIn = async () => {
//   const user = getUser()
//   console.log('User: ', user)
//
//   if (!isObject(user) || !user.hasOwnProperty('exp')) {
//     logout()
//     return Promise.reject('No user object')
//   }
//
//   if (hasTimeStampExpired(user.exp)) {
//     console.log('LOGIN IS EXPIRED')
//     await axiosInstance.get(API_URL.refreshToken())
//       .then(response => {
//         if (!handleLogin(response.data.jwt)) {
//           return Promise.reject('Could not login')
//         } else {
//           return Promise.resolve(true)
//         }
//       })
//       .catch(error => {
//         // localStorage.removeItem(KEY_USER);
//         return Promise.reject('Could not refresh token')
//       })
//   }
//
//   return Promise.resolve(true)
// }
//
// export const logout = () => {
//   localStorage.removeItem(KEY_USER)
// }
//
// export const parseJwtToken = token => {
//   const base64Url = token.split('.')[1];
//   const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//   const jsonPayload = decodeURIComponent(atob(base64)
//     .split('')
//     .map(c => '%' + ('00' + c.charCodeAt(0)
//       .toString(16))
//       .slice(-2)).join(''));
//
//   return JSON.parse(jsonPayload);
// };
